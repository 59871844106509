import React from 'react';

export default function Contact() {
  return (
    <div className="biopage">
      <h1 className="contitle">Contact Us</h1>
      <p className="context">
        21
        <sup>st</sup>
        &nbsp;Floor, 590 Madison Ave, New York, NY, 10022
        <br />
        <br />
        (212) 521 - 4379
      </p>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function Team() {
  return (
    <div className="team">
      <h2 className="teamtext">
        We leverage an industry-leading team of advisors:&nbsp;
        <Link to="/advisors/dolan" style={{ textDecoration: 'none', color: 'white' }}>
          <span className="linker">Peter R. Dolan</span>
        </Link>
        {/* ,&nbsp;
        <Link to="/advisors/mckinnell" style={{ textDecoration: 'none', color: 'white' }}>
          <span className="linker">Dr. Henry A. McKinnell, PhD</span>
        </Link> */}
        , and&nbsp;
        <Link to="/advisors/wilson" style={{ textDecoration: 'none', color: 'white' }}>
          <span className="linker">Robert N. Wilson</span>
        </Link>
        .
      </h2>
    </div>
  );
}

/* eslint-disable max-len */
import React from 'react';
import * as Scroll from 'react-scroll';

export default function Dolan() {
  React.useEffect(() => {
    Scroll.animateScroll.scrollTo('element', {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: 'scrolltarget',
    });
  }, []);

  return (
    <div className="biopage">
      <img src="/dolan.jpg" alt="Peter R. Dolan Headshot" className="headshot" />
      <h2 className="biotext">
        <span className="biotitle">Peter R. Dolan, Industry Advisory Board Member</span>
        <br />
        <hr />
        Mr. Dolan is former Chief Executive Officer and Chairman of Gemin X Pharmaceuticals, Inc, a Fund I portfolio company acquired by Cephalon in April 2011. Prior to Gemin X, Mr. Dolan was Chairman and Chief Executive Officer of Bristol-Myers Squibb from 2001 to 2006.
        <br />
        <br />
        Mr. Dolan has 25+ years of operating experience, with 18 years at Bristol-Myers Squibb and, prior to that, 8 years at General Foods.  Beginning his career in marketing at General Foods, he moved to  Bristol-Myers in 1988. Among other responsibilities, he was President of the company's Products Division (1993) and Mead Johnson Nutritionals (1995).  He was Group President of Medical Devices and Nutritionals (1997) and later promoted to become President of Bristol-Myers Squibb (2000) and Chairman and CEO of the company (2001).
        <br />
        <br />
        Mr. Dolan is a Vice Chair of the Board of Tufts University, Chairman of Child Obesity 180 and a member of the Board of Overseers of the Tuck School at Dartmouth.  Other current memberships include the Board of Directors of the National Center on Addiction and Substance Abuse at Columbia University and the Board of Directors of the Partnership for a Healthier America.  He formerly served on the boards of the American Express Company, C-Change (a cancer coalition organization), and was Chairman of the Pharmaceutical Research and Manufacturers of America.
        <br />
        <br />
        Mr. Dolan holds a Bachelor of Arts degree from Tufts University and a Masters of Business Administration degree from the Amos Tuck School of Business at Dartmouth.
      </h2>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import './App.css';
import Home from './Home';
import Navigation from './Navigation';
import Team from './Team';
import Roberts from './Roberts';
import Leheny from './Leheny';
import Advisory from './Advisory';
import Dolan from './Dolan';
// import McKinnell from './McKinnell';
import Wilson from './Wilson';
import Contact from './Contact';
import Portfolio from './Portfolio';
import News from './News';
import MobileNav from './MobileNav';
import PageNav from './PageNav';

function useWindowSize() {
  const isWindowClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(
    isWindowClient ? window.innerWidth : undefined,
  );

  useEffect(() => {
    function setSize() {
      setWindowSize(window.innerWidth);
    }
    if (isWindowClient) {
      window.addEventListener('resize', setSize);

      return () => window.removeEventListener('resize', setSize);
    }
  }, [isWindowClient, setWindowSize]);

  return windowSize;
}

export default function App() {
  const windowSize = useWindowSize();
  return (
    <div className="app" id="scrolltarget">
      {
        windowSize > 800
        && <Navigation />
      }
      {
        windowSize <= 800
        && <MobileNav />
      }
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/team" component={Team} />
        <Route path="/team/roberts" component={Roberts} />
        <Route path="/team/leheny" component={Leheny} />
        <Route exact path="/advisors" component={Advisory} />
        <Route path="/advisors/dolan" component={Dolan} />
        {/* <Route path="/advisors/mckinnell" component={McKinnell} /> */}
        <Route path="/advisors/wilson" component={Wilson} />
        <Route path="/contact" component={Contact} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/news" component={News} />
        <Route path="/navigation" component={PageNav} />
      </Switch>
    </div>
  );
}

import React from 'react';

export default function PortfolioElement(props) {
  const name = { props };
  return (
    <a href={name.props.name.address} style={{ textDecoration: 'none', color: '#000000' }} rel="noopener noreferrer" target="_blank">
      <div className="portfolio">
        <h2 className="portfolio-company">
          {name.props.name.name}
        </h2>
        <p className="blurb">
          {name.props.name.blurb}
        </p>
      </div>
    </a>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function MobileNav() {
  return (
    <div className="mobile-nav">
      <p className="company">VALENCE LIFE SCIENCES</p>
      <Link to="/navigation" style={{ textDecoration: 'none', color: '#000000' }}>
        <span className="hamburger" />
      </Link>
    </div>
  );
}

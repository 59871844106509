/* eslint-disable max-len */
import React from 'react';
import * as Scroll from 'react-scroll';

export default function Leheny() {
  React.useEffect(() => {
    Scroll.animateScroll.scrollTo('element', {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: 'scrolltarget',
    });
  }, []);

  return (
    <div className="biopage">
      <img src="/leheny.jpg" alt="A. Rachel Leheny, PhD Headshot" className="headshot" />
      <h2 className="biotext">
        <span className="biotitle">A. Rachel Leheny, PhD, Founding Managing Director</span>
        <br />
        <hr />
        In 2012, Dr. Leheny co-founded Valence Life Sciences. Prior to Valence Life Sciences, Dr. Leheny managed Caxton Advantage which she co-founded in 2004.
        <br />
        <br />
        Dr. Leheny has 30 years of experience in the life sciences industry. From April 2000 to May of 2002, Dr. Leheny was the Head of the Biotechnology Research Team and a Senior Vice President at Lehman Brothers. Dr. Leheny headed the Biotechnology Research Team at UBS Warburg and worked at Hambrecht & Quist, most recently as a Managing Director and Senior Biotechnology Analyst. Dr. Leheny was also named a Wall Street Journal research All-Star for stock picking for several years.
        <br />
        <br />
        Dr. Leheny obtained her A.B. in Chemistry with honors from Harvard College and a Ph.D. in chemistry from Columbia University. She did post-doctoral work at the University of California, Berkeley where she was an NIH fellow and a lecturer. 
        <br />
        <br />
        Dr. Leheny is CEO of CalciMedica, Inc., serves on its board and is a previous Chairman. She previously served on the boards of Anthera Pharmaceuticals and Corthera, Inc.  Dr. Leheny is currently a Director of the Clearity Foundation, a non-profit organization that provides molecular profiling for women with ovarian cancer.
      </h2>
    </div>
  );
}

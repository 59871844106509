import React from 'react';

export default function Wilson() {
  return (
    <div className="biopage">
      <img src="/wilson.jpg" alt="Robert N. Wilson Headshot" className="headshot" />
      <h2 className="biotext">
        <span className="biotitle">Robert N. Wilson, Industry Advisory Board Member</span>
        <br />
        <hr />
        Mr. Robert N. Wilson, a longtime leader in the pharmaceutical, medical device and biotech industries, is the former Chairman of Johnson & Johnson, PhRMA, Synta Pharmaceuticals Corporation, Caxton Health Holdings and Mevion Systems. Mr. Wilson was also a member of the board of directors of both Charles Schwab Corporation and Hess Corporation. 
        <br />
        <br />
        Mr. Wilson’s career at Johnson & Johnson notably included 15 years as the Vice Chairman of the Board of Directors and 20 years on the executive committee, working to expand the pharmaceutical, device and diagnostics programs. Mr. Wilson is an alumnus of Georgetown College.
      </h2>
    </div>
  );
}

/* eslint-disable max-len */
import React from 'react';
import * as Scroll from 'react-scroll';

export default function Roberts() {
  React.useEffect(() => {
    Scroll.animateScroll.scrollTo('element', {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: 'scrolltarget',
    });
  }, []);

  return (
    <div className="biopage">
      <img src="/roberts.jpg" alt="Eric W. Roberts Headshot" className="headshot" />
      <h2 className="biotext">
        <span className="biotitle">Eric W. Roberts, Founding Managing Director</span>
        <br />
        <hr />
        In 2012, Mr. Roberts co-founded investment firm, Valence Life Sciences. Prior to Valence Life Sciences, Mr. Roberts managed the Caxton Advantage Life Sciences Fund which he co-founded in 2005.
        <br />
        <br />
        Mr. Roberts has 30 years of experience in the finance, investment and life sciences industry and has completed hundreds of financings and merger transactions.  Prior to Caxton Advantage, he was Managing Director and Co-Head of the Global Healthcare Investment Banking Group at Lehman Brothers. Mr. Roberts was also a Managing Director, Partner and founder of the Life Sciences Department at Dillon, Read & Co. Inc. which was ultimately merged into UBS. At UBS, Mr. Roberts ran the Life Sciences investment banking team. Earlier in his career, Mr. Roberts worked at Citicorp in the Citicorp Venture Capital (CVC) group. 
        <br />
        <br />
        Mr. Roberts received a B.S. in Economics from the Wharton School of the University of Pennsylvania. Mr. Roberts is currently Vice Chairman of the Board of CalciMedica Inc. and serves as the company’s Chief Business Officer. He has served on a number of boards of public and private companies.  He is also a Director of the Cary Institute for Ecosystem Studies, the Atlantic Salmon Federation and is a past Director of the American Museum of Fly Fishing.
      </h2>
    </div>
  );
}

import React from 'react';

export default function PortfolioElement(props) {
  const name = { props };
  return (
    <div className="newsitem">
      <a href={name.props.name.link} style={{ textDecoration: 'none', color: '#000000' }} rel="noopener noreferrer" target="_blank">
        <div className="date">
          {name.props.name.date}
        </div>
        <hr style={{ color: 'rgb(27, 104, 137)', border: '1px solid' }} />
        <div className="comp">
          {name.props.name.company}
        </div>
        <div className="title">
          {name.props.name.title}
        </div>
        <div className="body">
          {name.props.name.body}
        </div>
      </a>
    </div>
  );
}

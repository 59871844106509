import React from 'react';
import * as Scroll from 'react-scroll';
import NewsItem from './NewsItem';

export default function News() {
  React.useEffect(() => {
    Scroll.animateScroll.scrollTo('element', {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: 'scrolltarget',
    });
  }, []);

  const newsArray = [
    {
      date: 'August 31, 2023',
      body: 'CalciMedica to Host Acute Pancreatitis Clinical Experts Event on September 21, 2023',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-host-acute-pancreatitis-clinical-experts-event',
    },
    {
      date: 'March 20, 2023',
      body: 'CalciMedica Announces Closing of Merger with Graybug Vision and Concurrent Private Placement',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-closing-merger-graybug-vision-and',
    },
    {
      date: 'January 17, 2023',
      body: 'Leap Therapeutics Acquires Flame Biosciences',
      link: 'https://investors.leaptx.com/news-releases/news-release-details/leap-therapeutics-acquires-flame-biosciences',
    },
    {
      date: 'November 21, 2022',
      body: 'Graybug and CalciMedica Enter into Definitive Merger Agreement',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/graybug-and-calcimedica-enter-definitive-merger-agreement',
    },
    {
      date: 'August 9, 2022',
      body: 'CalciMedica Announces Publication of Preclinical Data in EMBO Molecular Medicine Supporting the Development of CRAC Channel Inhibitors for Inflammatory Bowel Disease (IBD)',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-publication-preclinical-data-embo',
    },
    {
      date: 'April 14, 2022',
      body: 'CalciMedica Announces Publication of Results from CARDEA COVID-19 Study in Critical Care',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-publication-results-cardea-covid-19-study',
    },
    {
      date: 'March 10, 2022',
      body: 'CalciMedica Announces Publication of Peer-Reviewed Paper in The Journal of Physiology',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-publication-peer-reviewed-paper-journal',
    },
    {
      date: 'January 20, 2022',
      body: 'CalciMedica Initiates CARDEA-Plus: A Clinical Trial of Auxora™ in Patients with Critical COVID-19 Pneumonia',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-initiates-cardea-plus-clinical-trial-auxoratm',
    },
    {
      date: 'November 2, 2021',
      body: 'CalciMedica Strengthens Board of Directors with Appointment of Finance Veteran Allan L. Shaw',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-strengthens-board-directors-appointment-finance',
    },
    {
      date: 'September 14, 2021',
      body: 'CalciMedica Reports Topline Data from the AuxoraTM CARDEA Phase 2 COVID-19 Pneumonia Clinical Trial',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-reports-topline-data-auxoratm-cardea-phase-2-covid',
    },
    {
      date: 'June 2, 2021',
      body: 'CalciMedica Publishes Positive Data from Phase 2a Study of Auxora in Patients with Acute Pancreatitis and Announces First Patients Dosed in Phase 2b Study',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-publishes-positive-data-phase-2a-study-auxora',
    },
    {
      date: 'March 22, 2021',
      body: "Leap Therapeutics Presents DKN-01 Clinical Data at the Society of Gynecologic Oncology 2021 Annual Meeting on Women's Cancer",
      link: 'https://investors.leaptx.com/news-releases/news-release-details/leap-therapeutics-presents-dkn-01-clinical-data-society',
    },
    {
      date: 'March 11, 2021',
      body: 'CalciMedica Announces $21 Million Series D Financing',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-21-million-series-d-financing',
    },
    {
      date: 'February 2, 2021',
      body: 'CalciMedica Presents New Clinical Data Demonstrating Improved D-Dimer Biomarker Levels with Auxora™ in Severe COVID-19 Pneumonia at the 50th Critical Care Congress',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-presents-new-clinical-data-demonstrating-improved-d',
    },
    {
      date: 'November 3, 2020',
      body: 'Atea Pharmaceuticals Announces Closing of Initial Public Offering',
      link: 'https://ir.ateapharma.com/news-releases/news-release-details/atea-pharmaceuticals-announces-closing-initial-public-offering',
    },
    {
      date: 'August 18, 2020',
      body: 'CalciMedica Announces Peer-Reviewed Publication of Positive COVID-19 Data and Initiation of Blinded, Placebo-Controlled Trial of Auxora™ in Patients with Severe COVID-19 Pneumonia',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-peer-reviewed-publication-positive-covid',
    },
    {
      date: 'July 16, 2020',
      body: 'CalciMedica Announces Data Showing Auxora™ Substantially Improved Outcomes in Patients with Severe COVID-19 Pneumonia',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-data-showing-auxoratm-substantially',
    },
    {
      date: 'June 2, 2020',
      body: 'CalciMedica Announces Robert N. Wilson Formerly of J&J as Co-Chairman of the Board of Directors and Eric Roberts as Vice Chairman',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-appoints-robert-n-wilson-co-chairman-board-directors',
    },
    {
      date: 'May 28, 2020',
      body: 'CalciMedica Announces Positive Topline Data from Interim Analysis of Trial Evaluating Auxora™ in Patients with Severe COVID-19 Pneumonia',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-announces-positive-topline-data-interim-analysis',
    },
    {
      date: 'May 22, 2020',
      body: 'CalciMedica Raises $15 Million in Series C Financing Round Led by Valence Life Sciences',
      link: 'https://ir.calcimedica.com/news-releases/news-release-details/calcimedica-raises-15-million-series-c-financing-round-led',
    },
    {
      date: 'May 20, 2020',
      body: 'Atea Pharmaceuticals Announces IND Clearance of AT-527 for COVID-19 and $215 Million Financing',
      link: 'https://www.ateapharma.com/news/atea-pharmaceuticals-announces-ind-clearance-of-at-527-for-covid-19.html',
    },
    {
      date: 'January 23, 2020',
      body: 'Leap Therapeutics Presents Updated Data at the ASCO 2020 Gastrointestinal Cancers Symposium (ASCO GI) Global Meeting',
      link: 'https://investors.leaptx.com/news-releases/news-release-details/leap-therapeutics-presents-updated-data-asco-2020',
    },
    {
      date: 'December 9, 2019',
      body: 'Merck to Acquire ArQule For $2.7 Billion',
      link: 'https://www.merck.com/news/merck-to-acquire-arqule-advancing-leadership-in-oncology/',
    },
    {
      date: 'November 8, 2019',
      body: 'HCV Viral Kinetic Analysis Predicts Shorter Treatment duration with AT-527, a Purine Nucleotide Prodrug with Potent Pan-Genotypic Antiviral Activity in HCV-Infected Subjects Regardless of Cirrhosis Status',
      link: 'https://ir.ateapharma.com/static-files/26c04cd6-f4be-4e41-925f-27f7725c57ba',
    },
    {
      date: 'August 6, 2019',
      body: 'Leap Presents Positive Clinical Results for the Combination of DKN-01 Plus Keytruda',
      link: 'https://investors.leaptx.com/news-releases/news-release-details/leap-presents-positive-clinical-results-combination-dkn-01-plus',
    },
    {
      date: 'October 23, 2018',
      body: 'Stryker Completes Acquisition of Invuity, Inc.',
      link: 'https://investors.stryker.com/press-releases/news-details/2018/Stryker-completes-acquisition-of-Invuity-Inc/default.aspx',
    },
    {
      date: 'April 12, 2018',
      body: 'Atea Pharmaceuticals Reports Positive Proof of Concept Clinical Data With AT-527 for the Treatment of Chronic Hepatitis C',
      link: 'https://www.ateapharma.com/press-releases/atea-pharmaceuticals-reports-positive-proof-of-concept-clinical-data-with-at-527-for-the-treatment-of-chronic-hepatitis-c',
    },
    {
      date: 'November 15, 2017',
      body: 'Leap Therapeutics Offers $18 Million Private Placement Offering',
      link: 'https://investors.leaptx.com/news-releases/news-release-details/leap-therapeutics-announces-18-million-private-placement',
    },
    {
      date: 'August 3, 2017',
      body: 'Jazz Pharmaceuticals Announces FDA Approval of Vyxeos',
      link: 'https://investor.jazzpharma.com/news-releases/news-release-details/jazz-pharmaceuticals-announces-fda-approval-vyxeostm',
    },
    {
      date: 'May 31, 2016',
      body: 'Jazz Pharmaceuticals Announces Agreement to Acquire Celator Pharmaceuticals for $1.5 Billion',
      link: 'https://investor.jazzpharma.com/news-releases/news-release-details/jazz-pharmaceuticals-and-celator-pharmaceuticals-announce',
    },
    {
      date: 'March 30, 2016',
      body: 'Jazz Pharmaceuticals Announces FDA Approval of Defitelio (Defibrotide) for Treatment of Hepatic Veno-Occlusive Disease',
      link: 'https://investor.jazzpharma.com/news-releases/news-release-details/jazz-pharmaceuticals-announces-fda-approval-defitelior',
    },
    {
      date: 'March 14, 2016',
      body: 'Celator Pharmaceuticals Announces Statistically Significant Improvement in Overall Survival in Phase III Trial for Vyxeos (CPX-351) in High-Risk AML',
      link: 'https://www.jazzpharma.com/celatorpharmalp/',
    },
    {
      date: 'June 24, 2015',
      body: 'Celator Pharmaceuticals Announces Positive Response Rate Results From Phase III Study of CPX-351 in High-Risk AML Patients Demonstrating a 43% Relative Improvement',
      link: 'https://www.jazzpharma.com/celatorpharmalp/',
    },
    {
      date: 'June 15, 2015',
      body: 'Invuity Completes $55 Million IPO',
      link: 'https://invuity.com/',
    },
    {
      date: 'March 16, 2015',
      body: 'Anthera Announces Completion of Intermin Analysis for Phase III trial with Blisibimod in IgA Nepropathy',
      link: 'http://investor.anthera.com/press-releases',
    },
    {
      date: 'February 10, 2015',
      body: 'Anthera Announces Completion of Interim Analysis for Phase III Trial With Blisibimod in Lupus',
      link: 'http://investor.anthera.com/press-releases',
    },
    {
      date: 'December 19, 2013',
      body: 'Gentium Announces Sale to Jazz Pharmaceuticals for $1 Billion',
      link: 'https://www.jazzpharma.com/about/manufacturing/villa-guardia-italy-gentium-s-r-l/',
    },
    {
      date: 'August 21, 2013',
      body: 'Regado Completes $55 Million IPO',
      link: 'https://regadobio.com/',
    },
    {
      date: 'April 30, 2013',
      body: 'Celator Pharmaceuticals Raises $32.5 Million',
      link: 'https://www.jazzpharma.com/celatorpharmalp/',
    },
    {
      date: 'June 14, 2012',
      body: 'Invuity Raises $25 Million in Series D Funding',
      link: 'https://invuity.com/',
    },
    {
      date: 'July 17, 2012',
      body: 'Vivus Announces FDA Approval of Once Daily Qsymia for Obesity',
      link: 'https://ir.vivus.com/news-releases/news-release-details/vivus-announces-fda-approval-once-daily-qsymiatm-phentermine-and',
    },
    {
      date: 'January 17, 2012',
      body: 'Arqule Announces Tivantinib Meets Primary Endpoint Extending TTP in Phase II Trial in Hepatocellular Carcinoma',
      link: 'http://arqule.com/index.html',
    },
  ];
  return (
    <div className="news-case">
      <div className="newsreel">
        {
          newsArray.map((item) => <NewsItem name={item} key={newsArray.indexOf(item)} />)
        }
      </div>
    </div>
  );
}

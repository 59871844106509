import React from 'react';
import { Link } from 'react-router-dom';

export default function Team() {
  return (
    <div className="team">
      <h2 className="teamtext">
        Learn more about our Founding Managing Directors:&nbsp;
        <Link to="/team/leheny" style={{ textDecoration: 'none', color: 'white' }}>
          <span className="linker">A. Rachel Leheny, PhD</span>
        </Link>
        ,&nbsp;and&nbsp;
        <Link to="/team/roberts" style={{ textDecoration: 'none', color: 'white' }}>
          <span className="linker">Eric W. Roberts</span>
        </Link>
        .
      </h2>
    </div>
  );
}

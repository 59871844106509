import React from 'react';

export default function HomeStart() {
  return (
    <div>
      <div className="mainhome">
        <div className="tagline-box">
          <h1 className="tagline">
            We fund tomorrow&apos;s
            <br />
            novel medicines,
            <br />
            today.
          </h1>
        </div>
      </div>
      <div className="center-chevron">
        <span className="chevron" />
      </div>
      <div className="whitespace" />
      <div className="homepage" id="containerElement">
        <div className="row">
          <h1 className="tagline-2">
            <span className="outline">Valence Life Sciences</span>
            &nbsp;is the only life sciences venture firm with a proven record of success in late-stage investing.
          </h1>
          <img src="./lab.jpg" className="page-img" alt="Laboratory" />
        </div>
        <div className="row">
          <img src="./lab2.jpg" className="page-img" alt="Laboratory" />
          <h2 className="tagline-2">
            Our&nbsp;
            <span className="outline">experienced</span>
            <br />
            team draws on over 25 years of expertise.
          </h2>
        </div>
        <div className="row">
          <h2 className="tagline-2">
            Our&nbsp;
            <span className="outline">disciplined</span>
            <br />
            approach informs our investments.
          </h2>
          <img src="./lab3.jpg" className="page-img" alt="Laboratory" />
        </div>
        <div className="row">
          <img src="./lab4.jpg" className="page-img" alt="Laboratory" />
          <h2 className="tagline-2">
            Our&nbsp;
            <span className="outline">insight</span>
            &nbsp;into drivers of success in management and clinical plans guides our strategy.
          </h2>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function Navigation() {
  return (
    <div className="big-nav">
      <Link to="/" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">Home</h2>
      </Link>
      <Link to="/team" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">Team</h2>
      </Link>
      <Link to="/news" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">News</h2>
      </Link>
      <Link to="/portfolio" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">Portfolio</h2>
      </Link>
      <Link to="/advisors" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">Advisory Board</h2>
      </Link>
      <Link to="/contact" style={{ textDecoration: 'none', color: '#000000' }}>
        <h2 className="big-nav-ele">Contact</h2>
      </Link>
    </div>
  );
}

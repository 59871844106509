import React from 'react';
import * as Scroll from 'react-scroll';
import PortfolioElement from './PortfolioElement';
import LinklessPortfolioElement from './LinklessPortfolioElement';

export default function Portfolio() {
  React.useEffect(() => {
    Scroll.animateScroll.scrollTo('element', {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: 'scrolltarget',
    });
  }, []);

  const companies = [
    {
      name: 'anthera',
      blurb: 'A former public cardiovascular and inflammatory disease company. (NASDAQ: ANTH)',
    },
    {
      name: 'arqule',
      blurb: 'A public company focused on targeted cancer therapeutics which was acquired by Merck & Co. (NASDAQ: ARQL)',
    },
    {
      name: 'atea',
      blurb: 'A private company rapidly advancing transformative therapies for patients with life threatening viral diseases.',
      address: 'https://www.ateapharma.com/',
    },
    {
      name: 'calcimedica',
      blurb: 'A public company developing novel drugs for inflammatory and immune diseases. (NASDAQ: CALC)',
      address: 'http://www.calcimedica.com/',
    },
    {
      name: 'celator',
      blurb: 'A former public company focused on optimizing the combination of cancer therapies. Acquired by Jazz Pharmaceuticals. (NASDAQ: CPXX)',
      address: 'https://www.jazzpharma.com/celatorpharmalp/',
    },
    {
      name: 'corthera',
      blurb: 'A private company developing the hormone, Relaxin, in acute heart failure. Acquired by Novartis.',
    },
    {
      name: 'geminx',
      blurb: 'A private company focused on cancer therapeutics - acquired by Cephalon (now TEVA Pharmaceuticals).',
    },
    {
      name: 'gentium',
      blurb: 'A public Italian company treating vascular complications of cancer treatment. Acquired by Jazz Pharmaceuticals.',
      address: 'http://www.gentium.it/',
    },
    {
      name: 'invuity',
      blurb: 'A public company whose products enable less invasive surgery using proprietary photonic solutions. Acquired by Stryker Corporation. (NASDAQ: IVTY)',
    },
    {
      name: 'leap therapeutics',
      blurb: 'A public cancer therapeutics company with two molecules in development involved in immune responses to cancer. (NASDAQ: LPTX)',
      address: 'https://www.leaptx.com/',
    },
    {
      name: 'regado biosciences',
      blurb: 'A public company focused on use of aptamers for reversible treatments in acute care cardiovascular markets. Acquired by Tobira Therapeutics. (NASDAQ: TBRA)',
    },
    {
      name: 'sunesis',
      blurb: 'A public company focused on cancer therapeutics. (NASDAQ: SNSS)',
    },
    {
      name: 'transcept',
      blurb: 'A former public company focused on insomnia acquired by Paratek Pharmaceuticals. (NASDAQ: TSPT)',
    },
    {
      name: 'vivus',
      blurb: 'A public company primarily focused on treatments for obesity and diabetes. (NASDAQ: VVUS)',
      address: 'http://www.vivus.com/',
    },
  ];
  return (
    <div className="portfolio-group">
      {
        companies.map((company) => ('address' in company ? <PortfolioElement name={company} key={company.name} /> : <LinklessPortfolioElement name={company} key={company.name} />))
      }
    </div>
  );
}

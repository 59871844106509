import React from 'react';

export default function LinklessPortfolioElement(props) {
  const name = { props };
  return (
    <div className="portfolio">
      <h2 className="portfolio-company">
        {name.props.name.name}
      </h2>
      <p className="blurb">
        {name.props.name.blurb}
      </p>
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';

export default function Navigation() {
  return (
    <div className="navigation">
      <p className="company">VALENCE LIFE SCIENCES</p>
      <Link to="/" style={{ textDecoration: 'none', color: '#000000' }}><p className="nav-ele"> HOME </p></Link>
      <div className="dropdown">
        <Link to="/team" style={{ textDecoration: 'none', color: '#000000' }}><p className="dropbutton"> TEAM </p></Link>
        <div className="drop-content">
          <Link to="/team/roberts" style={{ textDecoration: 'none', color: '#000000' }}><p className="drop-ele"> ERIC W. ROBERTS </p></Link>
          <Link to="/team/leheny" style={{ textDecoration: 'none', color: '#000000' }}><p className="drop-ele"> A. RACHEL LEHENY, PHD </p></Link>
        </div>
      </div>
      <Link to="/news" style={{ textDecoration: 'none', color: '#000000' }}><p className="nav-ele"> NEWS </p></Link>
      <Link to="/portfolio" style={{ textDecoration: 'none', color: '#000000' }}><p className="nav-ele"> PORTFOLIO </p></Link>
      <div className="dropdown">
        <Link to="/advisors" style={{ textDecoration: 'none', color: '#000000' }}><p className="dropbutton"> ADVISORY BOARD </p></Link>
        <div className="drop-content">
          <Link to="/advisors/dolan" style={{ textDecoration: 'none', color: '#000000' }}><p className="drop-ele"> PETER R. DOLAN </p></Link>
          {/* <Link to="/advisors/mckinnell" style={{ textDecoration: 'none', color: '#000000' }}><p className="drop-ele"> DR. HENRY A. MCKINNELL, PHD </p></Link> */}
          <Link to="/advisors/wilson" style={{ textDecoration: 'none', color: '#000000' }}><p className="drop-ele"> ROBERT N. WILSON </p></Link>
        </div>
      </div>
      <Link to="/contact" style={{ textDecoration: 'none', color: '#000000' }}><p className="nav-ele"> CONTACT </p></Link>
    </div>
  );
}
